import React from 'react';

const Header = () => {
    const headerStyle = {
        textAlign: 'center',
    color: 'white',
    padding: '60px 20px', // Adjust padding for top and bottom, reduce padding for left and right
    marginBottom: '50px', // Reduce margin bottom for smaller screens
    maxWidth: '800px', // Set max width for larger screens
    margin: '0 auto', // Center the header horizontally
    marginTop: '60px',
    };

    const textStyle = {
        textAlign: 'justify',
        color: 'white',
        fontSize: '18px', // Font size for larger screens
        marginLeft: 'auto', // Center text horizontally on larger screens
        marginRight: 'auto', // Center text horizontally on larger screens
        maxWidth: '600px', // Limit text width for larger screens
    };

    // Media query for smaller screens
    const mediaQuery = `@media (max-width: 768px) {
        padding: 40px 10px; // Adjust padding for smaller screens
        marginBottom: 30px; // Adjust margin bottom for smaller screens
        h1 {
            fontSize: 22px; // Adjust font size for smaller screens
        }
        p {
            fontSize: 16px; // Adjust font size for smaller screens
            maxWidth: 100%; // Remove max width for smaller screens
            marginLeft: 20px; // Adjust text alignment for smaller screens
            marginRight: 20px; // Adjust text alignment for smaller screens
        }
    }`;

    return (
        <div style={headerStyle}>
            <style>{mediaQuery}</style>
            <h1>What is hackX?</h1>
            <p style={textStyle}>
                hackX is a renowned inter-university startup challenge organized by the Industrial Management Science Students' Association (IMSSA) of the Department of Industrial Management, University of Kelaniya. Approaching its ninth edition in 2024, the event will center on the theme "Sustainable Innovation," eagerly anticipating groundbreaking ideas that will shape the future of Sri Lankan startups.
                <br /><br />
                hackX 9.0 aims to provide undergraduates with a premier platform to present their ideas to a panel of esteemed industry experts. This event offers the opportunity to build valuable connections and gain support from investors to bring their innovative ideas to life.
            </p>
        </div>
    );
};

export default Header;
